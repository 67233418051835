import React, { useState } from 'react';

import { Form } from 'libs/forms';
import {
  FormThemeProvider,
  InputField,
  PasswordField,
} from 'ui/ecosystems/forms';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'ui';

import styled, { css } from 'styled-components';

import { InputWrapper } from 'ui/ecosystems/forms/atoms/input-wrapper';

import { usePetOwnerSignInService } from 'features/pet-owner-profile';
import { useServiceProviderSignInService } from 'features/service-provider-profile';

import { createMediaMaxWidth, mediaPhone, mediaTablet } from 'ui/styles/media';

import { Error, FormFieldsBlock } from '../../atoms/forms';

export function SignInForm() {
  const history = useHistory();

  const { authAsPetOwner } = usePetOwnerSignInService();
  const { authAsServiceProvider } = useServiceProviderSignInService();

  const [error, setError] = useState(null);
  const [accType, setAccType] = useState('PO');

  async function serviceProviderSignIn(data: {
    email: string;
    password: string;
  }) {
    try {
      await authAsServiceProvider(data);
      history.push('/service-provider-profile');
    } catch (e) {
      const error = e.response?.data?.message ?? 'Something wrong';
      setError(error);
    }
  }

  async function petOwnerSignIn(data: { email: string; password: string }) {
    try {
      await authAsPetOwner(data);
      history.push('/pet-owner-profile');
    } catch (e) {
      const error = e.response?.data?.message ?? 'Something wrong';
      setError(error);
    }
  }

  async function handleFormSubmit(values: { email: string; password: string }) {
    setError(null);
    switch (accType) {
      case 'PO':
        await petOwnerSignIn(values);
        break;
      case 'SP':
        await serviceProviderSignIn(values);
    }
  }

  return (
    <Form
      onSubmit={handleFormSubmit}
      render={({ handleSubmit, submitSucceeded }) => (
        <FormThemeProvider middle>
          <FormFieldsBlock>
            <Switcher>
              <SwitcherOption
                onClick={() => {
                  setAccType('PO');
                }}
                isActive={accType === 'PO'}
              >
                as Pet Owner
              </SwitcherOption>
              <SwitcherOption
                onClick={() => {
                  setAccType('SP');
                }}
                isActive={accType === 'SP'}
              >
                as Service Provider
              </SwitcherOption>
            </Switcher>
            <InputField name="email" placeholder="E-mail" />
            <PasswordField name="password" placeholder="Password" />
            {error && <Error>{error}</Error>}
            <Button onClick={handleSubmit} medium>
              Login
            </Button>
            <Link to="/reset-password">Forgot password?</Link>
          </FormFieldsBlock>
        </FormThemeProvider>
      )}
    />
  );
}

const SwitcherOption = styled(InputWrapper)<{ isActive: boolean }>`
  width: 50%;
  
  justify-content: center;
  color: var(--darkBlue);
  font-size: 16px;
  line-height: 1.5;
  ${props =>
    props.isActive &&
    css`
      background-color: #f7f8ff;
      color: var(--black);
    `};
  };
  
  ${createMediaMaxWidth(1100)} {
    font-size: 12px
  }
`;

const Switcher = styled.div`
  display: flex;
  cursor: pointer;
  transition: all 200ms ease;

  & ${SwitcherOption}:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-width: 1px;
  }
  & ${SwitcherOption}:nth-child(2) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left-width: 1px;
  }
`;
