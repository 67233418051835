import React from 'react';
import { Link } from 'react-router-dom';

import { SignInForm } from 'features/authentication';

import s from './login.page.module.css';

export default function LoginPage() {
  return (
    <div className={s.wrapper}>
      <div className={s.logoSide}>
        <div className={s.logo} />
      </div>
      <div className={s.mainSide}>
        <div className={s.header}>
          <Link className={s.backLink} to="/" />
        </div>
        <div className={s.body}>
          <h1 className={s.title}>Sign in to Pawzr</h1>
          <SignInForm />
        </div>
        <div className={s.footer}>
          <div className={s.text}>Don't have an account yet?</div>
          <div className={s.links}>
            <Link className={s.link} to="/sign-up-pet-owner">
              Create Pet Owner Profile
            </Link>
            &nbsp;or&nbsp;
            <Link className={s.link} to="/sign-up-provider">
              List Your Business
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
